import React, { Component } from 'react'
import './css/KFZ.css'


class KFZ extends Component {
    constructor(props) {
        super(props)

        this.data = this.props.e
        this.html = this.generate_kfz()
    }


    generate_kfz(){
        let {kreis, jahr, farbe, siegel, buchstaben, ziffern} = this.data

        if( !kreis || !(jahr || farbe) || !siegel || !buchstaben || !ziffern ){
            return null
        }

        kreis = kreis.toLocaleUpperCase().split('')
        buchstaben = buchstaben.toLocaleUpperCase().split('')
        ziffern = ziffern.split('')

        this.kreis = []
        this.plakette = []
        this.siegel = []
        this.buchstaben = []
        this.ziffern = []

        let self = this
        kreis.map((e,i)=>{
            self.kreis.push(<span className={e.toLocaleLowerCase()} key={i}>{e}</span>)
        })

        buchstaben.map((e,i)=>{
            self.buchstaben.push(<span className={e.toLocaleLowerCase()} key={i}>{e}</span>)
        })

        ziffern.map((e,i)=>{
            self.ziffern.push(<span className={'n'+e} key={i}>{e}</span>)
        })


        jahr = this.farbe()
        this.plakette.push(<span className={jahr} key='1'></span>)
        this.plakette.push(<span className='datum' key='2'></span>)

        this.siegel.push(<span className={siegel} key='1'>-</span>)
    }


    // https://de.wikipedia.org/wiki/Kfz-Kennzeichen_(Deutschland)
    farbe(){
        let farbe = this.data.farbe
        if( farbe ){
            let erlaubt = ['braun', 'rosa', 'grün', 'orange', 'blau', 'gelb']
            if( erlaubt.includes(farbe)){
                return farbe
            }else{
                return 'weiß'
            }
        }else{
            let plakette = parseInt(this.data.jahr)
            if( Number.isNaN( plakette )){
                return 'weiß'
            }

            if( plakette > 1973){
                plakette -=1974
                switch (plakette%6) {
                    case 0:
                        return 'braun'
                    case 1:
                        return 'rosa'
                    case 2:
                        return 'grün'
                    case 3:
                        return 'orange'
                    case 4:
                        return 'blau'
                    case 5:
                        return 'gelb'
                    default:
                        return 'weiß'
                }
            }else{
                return 'weiß'
            }
        }
    }


    render() {
        return (
            <div className='KFZ'>
                <div className="kennzeichen" key='1'>
                    <div className="schild" >
                        <span className="kreis" >
                        {
                            this.kreis
                        }
                        </span>
                        <span className="plakette" >
                        {
                            this.plakette
                        }
                        </span>
                        <span className="siegel" >
                        {
                            this.siegel
                        }
                        </span>
                        <span className="buchstaben" >
                        {
                            this.buchstaben
                        }
                        </span>
                        <span className="ziffern" >
                        {
                            this.ziffern
                        }
                        </span>
                    </div>
                </div>
            </div>
        )
    }
}

export default KFZ
