import React, { Component } from 'react'
import wd_gfe from './svg/WD_GFE.svg'

class HeaderServiceSelector extends Component {
    constructor(props){
        super(props)

        this.bi_flavor = this.props.e.bi_flavor

        this.app = this.props.app

        let s_h = this.app.getRenderDataElement('header', 'show_home') 
        this.show_home = !(s_h === false || s_h === 'false') // legacy: wenn kein show_home, soll es angezeigt werden

        this.logo = ''
        try{
            let logo = this.props.e.logo

            if ( logo.match( /^http/ ) ){
                this.logo = logo
            }

            if ( logo.match( /^data:/ ) ){
                this.logo = logo
            }
            
            if ( logo.match( /^helper:/ ) ){
                logo = logo.replace('helper:','')
                this.logo = this.props.app.helper + '/' + logo
            }
            
            if( !logo.match( /^http/ ) && !logo.match( /^helper:/ ) ){

                this.logo = require( `${ logo }`  )
            }

            if( this.props.e.logo.match(/^\.\//) ){
                this.icon = require( `${ logo }` )
            }

            if( this.bi_flavor ){
                this.bi_flavor_icon = require( `${ this.bi_flavor }` )
            }
            
        } catch ( error ){
            console.log('error in HeaderServiceSelector while loading icon')
        }
    }

    componentDidMount(){
        this.props.app.setHome()

        let root = document.getElementById('root')
        try {
            let header = document.getElementsByClassName('HeaderServiceSelector')[0]
            let header_style = document.defaultView.getComputedStyle( header, null )
            let header_height = header_style.getPropertyValue('height')
            root.style.marginTop = header_height
        } catch (error) {
            root.style.marginTop = '50vh'
        }
    }


    home(){
        if( !this.show_home ){
            return null
        }


        let home = ''
        let query = document.location.search
        let url_params = new URLSearchParams( query )
        let { app } = this.props

        let use_cl = url_params.get('usecheckliste')
        let home_item = localStorage.getItem( use_cl || app.pid  )
        if( home_item ){
            home = JSON.parse( home_item ).home
        }
            
        if( home ){
            window.location.href = home
        }else{
            url_params.delete('class')
            url_params.delete('display')
            url_params.delete('refuse')
            url_params.delete('parentid')
            url_params.delete('qrid')

            let { origin, pathname } = document.location
            window.location.href = `${origin}${pathname}?${url_params.toString()}`
        }
    }


    render() {
        let service
        try{
            service = this.props.app.type.SERVICE
        }catch(e){}

        let outer_tds = {height: '100%', display: 'grid' }
        let flavor_style = {
            backgroundColor: '#313131',
            padding: '0.3rem',
            height: '3rem',
            borderRadius: '0.2rem',
        }

        return (
            <div className='HeaderServiceSelector'>
                <table>
                    <tbody>    
                        <tr>
                            <td className='blackBG'></td>
                            <td className='hss_main'>
                                <div className='hss_1' style={ this.props.e.logo_css  }>
                                    <img src={this.logo} style={ this.props.e.img_css  }/>
                                    {
                                        this.bi_flavor_icon ?
                                            <img src={this.bi_flavor_icon} style={ Object.assign( flavor_style, this.props.e.img_css)  }/>
                                        :
                                            null
                                    }
                                </div>

                                {
                                    this.props.e.wdlogo === 'false' ? 
                                        null
                                    :
                                        <div className='hss_2'>
                                            {
                                                this.bi_flavor_icon ? 
                                                <img src={wd_gfe} alt='wd_gfe' style={{height: '3rem', display: 'flex'}}/>
                                                :
                                                <span>
                                                    Whats<b>Down</b>
                                                </span>
                                            }
                                        </div>
                                }
                            </td>
                            <td className='orangeBG'></td>
                        </tr>

                        {
                            this.props.e.showDevicename === "false" || (!this.show_home && !this.props.app.title) ? 
                                null
                            :
                                <tr>
                                    <td style={outer_tds}>
                                        <div className='location trans_col'>A</div>
                                    </td>
                                    <td>
                                        <div className='location' onClick={ () => this.home() } >
                                            {
                                                this.show_home ?
                                                    <span className='modifier_button_icon icomoon_general icomoon_home_header'></span>
                                                :
                                                    null
                                            }
                                            <span>
                                                {
                                                    this.props.app.title // || `${service} Selector` // setze "title":"..." in json element mit "type": "header"
                                                }
                                            </span>
                                        </div>
                                    </td>
                                    <td style={outer_tds}>
                                        <div className='location trans_col'>A</div>
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default HeaderServiceSelector
